<template>
     <Login/>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/login/Login.vue'

export default 
{

  components: 
  {
    Login
  }
}
</script>
